import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Blog = () => (
  <Layout>
    <SEO title="blog" />
    <main className="main post-feed">
        <a className="post-card" target="_blank" href="https://www.freecodecamp.org/news/11-dos-and-donts-for-your-first-programming-job/">
            <div className="post-card-image blog-3">
            </div>
            <h4>11 dos and don'ts for your first programming job</h4>
            <div className="blog-author">
              <span className="blog-author-icon freecodecamp"></span>
              <p>FreeCodeCamp</p>
            </div>
            <br/>
            <p>Firsts are exciting but can also be overwhelming. When I started my first programming job, I knew there’s a lot I needed to learn tech-wise. But...</p>
            <br/>
            <p className="post-card-footer">June 2019</p>
        </a>
        <a className="post-card" target="_blank" href="https://blog.logrocket.com/the-visual-learners-guide-to-async-js-62a0a03d1d57/">
            <div className="post-card-image blog-2">
            </div>
            <h4>The visual learner’s guide to async JS</h4>
            <div className="blog-author">
              <span className="blog-author-icon logrocket"></span>
              <p>LogRocket</p>
            </div>
            <br/>
            <p>Have you ever watched or read hours’ worth of tutorials but were still left confused? That’s how I felt when I first dove into learning asynchronous...</p>
            <br/>
            <p className="post-card-footer">May 2019</p>
        </a>
        <a className="post-card" target="_blank" href="https://www.freecodecamp.org/news/from-zero-to-deploy-how-i-created-a-static-website-from-scratch-using-netlify-gatsby-ebca82612ffd/">
            <div className="post-card-image blog-1">
            </div>
            <h4>From Zero to Deploy: how I created a static website from scratch using Netlify + Gatsby</h4>
            <div className="blog-author">
              <span className="blog-author-icon freecodecamp"></span>
              <p>FreeCodeCamp</p>
            </div>
            <br/>
            <p>After my first year working as a frontend web developer, I got the idea to have my own personal site. It’d be a platform to showcase my work, share content...</p>
            <br/>
            <p className="post-card-footer">February 2019</p>
        </a>
    </main>
  </Layout>
)

export default Blog
